// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { InteractionExprEditor } from "@/wab/client/components/sidebar-tabs/ComponentPropsSection";
import { PropEditorRow } from "@/wab/client/components/sidebar-tabs/PropEditorRow";
import { ensureGenericFuncTypes } from "@/wab/client/components/sidebar-tabs/StateManagement/HandlerSection";
import { useSourceOp } from "@/wab/client/components/sidebar-tabs/useSourceOp";
import { LabeledItem } from "@/wab/client/components/sidebar/sidebar-helpers";
import StyleSelect from "@/wab/client/components/style-controls/StyleSelect";
import StyleToggleButton from "@/wab/client/components/style-controls/StyleToggleButton";
import StyleToggleButtonGroup from "@/wab/client/components/style-controls/StyleToggleButtonGroup";
import {
  DefaultActionBuilderProps,
  PlasmicActionBuilder,
} from "@/wab/client/plasmic/plasmic_kit_state_management/PlasmicActionBuilder";
import {
  ACTIONS_META,
  ActionType,
  BLOCKED_RUN_INTERACTION_MESSAGE,
  extractDataCtx,
  generateActionMetaForGlobalAction,
  generateInteractionContextData,
} from "@/wab/client/state-management/interactions-meta";
import {
  canRunInteraction,
  runInteraction,
} from "@/wab/client/state-management/preview-steps";
import { StudioCtx } from "@/wab/client/studio-ctx/StudioCtx";
import { ViewCtx } from "@/wab/client/studio-ctx/view-ctx";
import { TutorialEventsType } from "@/wab/client/tours/tutorials/tutorials-events";
import { assert, ensureInstance, spawn } from "@/wab/shared/common";
import { HighlightBlinker } from "@/wab/commons/components/HighlightBlinker";
import { combineProps } from "@/wab/commons/components/ReactUtil";
import { codeLit, InteractionConditionalMode } from "@/wab/shared/core/exprs";
import { mkNameArg } from "@/wab/shared/core/lang";
import {
  HighlightInteractionRequest,
  isPlainObjectPropType,
  maybePropTypeToDisplayName,
  propTypeToWabType,
} from "@/wab/shared/code-components/code-components";
import {
  Component,
  CustomCode,
  DataSourceOpExpr,
  ensureKnownDataSourceOpExpr,
  EventHandler,
  Expr,
  Interaction,
  isKnownExpr,
  ObjectPath,
  TplComponent,
  TplTag,
} from "@/wab/shared/model/classes";
import { renameInteractionAndFixExprs } from "@/wab/shared/refactoring";
import { EventHandlerKeyType } from "@/wab/shared/core/tpls";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { Popover } from "antd";
import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

export interface ActionBuilderProps extends DefaultActionBuilderProps {
  tpl: TplComponent | TplTag;
  sc: StudioCtx;
  vc: ViewCtx;
  component: Component;
  interaction: Interaction;
  eventHandlerKey: EventHandlerKeyType;
  onCollapseClick: () => void;
  menu: React.ReactNode;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  highlightOnMount?: HighlightInteractionRequest;
  eventHandlerExpr: EventHandler;
}

function ActionBuilder_(
  props: ActionBuilderProps,
  ref: HTMLElementRefOf<"div">
) {
  const {
    tpl,
    sc,
    vc,
    component,
    interaction,
    eventHandlerKey,
    menu,
    dragHandleProps,
    onCollapseClick,
    highlightOnMount,
    eventHandlerExpr,
    isCollapsed,
    ...rest
  } = props;

  const [hover, setHover] = React.useState(false);
  const [interactionName, setInteractionName] = React.useState(
    interaction.interactionName
  );
  const [isEditingInteractionnName, setIsEditingInteractionName] =
    React.useState(false);
  const [dataSourceExpr, setDataSourceExpr] = React.useState<
    DataSourceOpExpr | undefined
  >(undefined);
  const { source: sourceMeta } = useSourceOp(
    dataSourceExpr?.sourceId,
    dataSourceExpr?.opName
  );
  const actionMeta = getActionMeta(sc, interaction.actionName);
  const args = React.useMemo(
    () =>
      Object.fromEntries(
        interaction.args.map(({ name, expr }) => [name, expr])
      ),
    [interaction.args]
  );
  const interactionsCtx = React.useMemo(
    () =>
      generateInteractionContextData(
        component,
        tpl,
        interaction,
        eventHandlerKey,
        vc,
        sourceMeta
      ),
    [component, interaction, sourceMeta]
  );
  const [isInteractionDefaultName, setIsInteractionDefaultName] =
    React.useState(
      () =>
        actionMeta?.getDefaultName?.(component, args, interactionsCtx) ===
        interaction.interactionName
    );
  React.useEffect(() => {
    if (isInteractionDefaultName && actionMeta) {
      const newInteractionDefaultName = actionMeta.getDefaultName?.(
        component,
        args,
        interactionsCtx
      );
      if (
        newInteractionDefaultName &&
        newInteractionDefaultName !== interaction.interactionName
      ) {
        spawn(
          sc.change(({ success }) => {
            renameInteractionAndFixExprs(
              interaction,
              newInteractionDefaultName
            );
            setInteractionName(interaction.interactionName);
            return success();
          })
        );
      }
    }
  }, [args, isInteractionDefaultName, actionMeta]);
  const actionEnv = extractDataCtx(
    vc,
    tpl,
    undefined,
    interaction,
    eventHandlerKey
  );
  const hasCachedStepValue = vc.studioCtx.hasCached$stepValue(interaction.uuid);
  const enabledPreviewSteps = vc.studioCtx.appCtx.appConfig.previewSteps;
  const disabledRunInteraction = !canRunInteraction(interaction, vc);

  return (
    <div style={{ position: "relative" }}>
      <PlasmicActionBuilder
        {...rest}
        isCollapsed={
          isCollapsed &&
          !sc.onboardingTourState.flags.keepActionBuilderUncollapsed
        }
        play={{
          render: (ps, Comp) => (
            <Popover
              overlayStyle={{
                maxWidth: 300,
              }}
              content={
                hasCachedStepValue
                  ? // TODO: improve preview value
                    `Current value: ${(() => {
                      try {
                        return JSON.stringify(
                          vc.studioCtx.getCached$stepValue(interaction.uuid)
                        );
                      } catch (e) {
                        return `(cannot display)`;
                      }
                    })()}`
                  : disabledRunInteraction
                  ? BLOCKED_RUN_INTERACTION_MESSAGE
                  : "Run this action"
              }
            >
              <Comp
                {...ps}
                onClick={() => {
                  if (!disabledRunInteraction) {
                    runInteraction(interaction, vc, tpl);
                  }
                }}
              />
            </Popover>
          ),
        }}
        previewSteps={
          enabledPreviewSteps
            ? hasCachedStepValue
              ? "finished"
              : disabledRunInteraction
              ? "unable"
              : "notStarted"
            : undefined
        }
        root={{
          onMouseEnter: () => setHover(true),
          onMouseLeave: () => setHover(false),
          ref,
        }}
        actionItem={{
          render: () => (
            <LabeledItem label={"Action"} layout={"vertical"}>
              <StyleSelect
                data-plasmic-prop={"action-name"}
                valueSetState={"isSet"}
                {...{
                  value: interaction.actionName,
                  onChange: (val) => {
                    if (!val) {
                      return;
                    }
                    spawn(
                      sc.change<Error>(({ success, failure }) => {
                        const newActionMeta = getActionMeta(sc, val);
                        if (!newActionMeta) {
                          return failure(
                            new Error(`Unknown action type ${val}`)
                          );
                        }

                        interaction.actionName = val;
                        const defaultArgs = makeDefaultArgs(
                          vc,
                          component,
                          newActionMeta
                        );
                        interaction.args = Object.entries(defaultArgs).map(
                          ([name, expr]) => mkNameArg({ name, expr })
                        );
                        if (isInteractionDefaultName) {
                          renameInteractionAndFixExprs(
                            interaction,
                            newActionMeta.getDefaultName?.(
                              component,
                              {},
                              interactionsCtx
                            ) ?? newActionMeta.displayName
                          );
                        }

                        if (val === "dataSourceOp") {
                          sc.tourActionEvents.dispatch({
                            type: TutorialEventsType.PickedDataSourceOption,
                          });
                        }

                        return success();
                      })
                    );
                  },
                  children: [
                    ...Object.entries(ACTIONS_META)
                      .filter(
                        ([__, meta]) =>
                          !meta.hidden?.({
                            siteInfo: sc.siteInfo,
                          })
                      )
                      .map(([aName, aMeta]) => (
                        <StyleSelect.Option key={aName} value={aName}>
                          {aMeta.displayName}
                        </StyleSelect.Option>
                      )),
                    ...[
                      ...Array.from(sc.getRegisteredContextsMap().values()),
                      ...Array.from(sc.getHostLessContextsMap().values()),
                    ].map(({ meta }) => {
                      const globalActions =
                        "globalActions" in meta
                          ? meta.globalActions
                          : undefined;
                      if (!globalActions || isEmpty(globalActions)) {
                        return null;
                      }
                      return (
                        <StyleSelect.OptionGroup
                          title={meta.displayName ?? meta.name}
                        >
                          {Object.entries(globalActions).map(
                            ([globalAction, globalActionMeta]: [
                              string,
                              any
                            ]) => (
                              <StyleSelect.Option
                                value={`${meta.name}.${globalAction}`}
                              >
                                {globalActionMeta.displayName ?? globalAction}
                              </StyleSelect.Option>
                            )
                          )}
                        </StyleSelect.OptionGroup>
                      );
                    }),
                  ],
                }}
              />
            </LabeledItem>
          ),
        }}
        label={"Action"}
        actionName={interaction.interactionName}
        isEditingActionName={isEditingInteractionnName}
        edit={{
          onClick: () => setIsEditingInteractionName(true),
        }}
        editActionName={{
          value: interactionName,
          onChange: (e) => setInteractionName(e.target.value),
          onBlur: () => {
            spawn(
              sc.change(({ success }) => {
                if (interactionName === "" && actionMeta) {
                  const defaultInteractionName = actionMeta.getDefaultName?.(
                    component,
                    args,
                    interactionsCtx
                  );
                  renameInteractionAndFixExprs(
                    interaction,
                    defaultInteractionName
                  );
                  setInteractionName(interaction.interactionName);
                  setIsInteractionDefaultName(true);
                } else if (interactionName !== interaction.interactionName) {
                  renameInteractionAndFixExprs(interaction, interactionName);
                  setInteractionName(interaction.interactionName);
                  setIsInteractionDefaultName(false);
                }
                return success();
              })
            );
            setIsEditingInteractionName(false);
          },
          autoFocus: true,
        }}
        isConditional={
          interaction.conditionalMode === InteractionConditionalMode.Expression
        }
        conditionalMode={{
          render: () => (
            <StyleToggleButtonGroup
              value={interaction.conditionalMode}
              onChange={(val) =>
                sc.change(({ success }) => {
                  interaction.conditionalMode =
                    val as InteractionConditionalMode;
                  return success();
                })
              }
            >
              <StyleToggleButton
                value={InteractionConditionalMode.Always}
                stretched
                data-plasmic-prop="mode-always"
              >
                <div className="text-m">Always</div>
              </StyleToggleButton>
              <StyleToggleButton
                value={InteractionConditionalMode.Never}
                stretched
                data-plasmic-prop="mode-never"
              >
                <div className="text-m">Never</div>
              </StyleToggleButton>
              <StyleToggleButton
                value={InteractionConditionalMode.Expression}
                stretched
                data-plasmic-prop="mode-when"
              >
                <div className="text-m">When...</div>
              </StyleToggleButton>
            </StyleToggleButtonGroup>
          ),
        }}
        condExpr={{
          render: () => (
            <InteractionExprEditor
              viewCtx={vc}
              tpl={tpl}
              value={interaction.condExpr}
              onChange={(val) => {
                if (!val) {
                  return;
                }
                spawn(
                  sc.change(({ success }) => {
                    interaction.condExpr = ensureInstance(
                      val,
                      ObjectPath,
                      CustomCode
                    );
                    ensureGenericFuncTypes(eventHandlerExpr, eventHandlerKey);
                    return success();
                  })
                );
              }}
              eventHandlerKey={eventHandlerKey}
              currentInteraction={interaction}
              data-plasmic-prop={"conditional-expr"}
              component={component}
            />
          ),
        }}
        collapse={{
          onClick: () => onCollapseClick(),
        }}
        menuButton={{ menu }}
        alwaysShowDragHandle
        dragHandle={{
          props: dragHandleProps
            ? combineProps(dragHandleProps, {
                onMouseDown: (e: React.MouseEvent) => {
                  e.stopPropagation();
                },
                style: {
                  display: hover && !props.isDragging ? "flex" : "none",
                },
              })
            : { display: "none" },
        }}
      >
        {actionMeta &&
          Object.entries(actionMeta.parameters).map(
            ([parameterName, parameterMeta]) => {
              if (
                isPlainObjectPropType(parameterMeta) &&
                "hidden" in parameterMeta &&
                parameterMeta.hidden?.(args, interactionsCtx, { path: [] })
              ) {
                return null;
              }
              const failableType = propTypeToWabType(
                sc.site,
                parameterMeta
              ).result;
              assert(
                !failableType.isError,
                `couldn't parse parameter meta: ${parameterMeta}`
              );
              const type = failableType.value;
              const value = args[parameterName];
              const label =
                maybePropTypeToDisplayName(parameterMeta) ?? parameterName;

              return (
                <div style={{ position: "relative", width: "100%" }}>
                  <PropEditorRow
                    key={`${parameterName}-${type}`}
                    expr={value}
                    label={label}
                    attr={parameterName}
                    definedIndicator={{ source: "invariantable" }}
                    onDelete={() => {
                      const newArgs = { ...args };
                      delete newArgs[parameterName];
                      actionMeta.resetDependentArgs?.(
                        newArgs,
                        interactionsCtx,
                        parameterName
                      );
                      spawn(
                        sc.change(({ success }) => {
                          interaction.args = Object.entries(newArgs).map(
                            ([name, expr]) =>
                              mkNameArg({
                                name,
                                expr,
                              })
                          );
                          return success();
                        })
                      );
                    }}
                    onChange={(val) => {
                      const newExpr = isKnownExpr(val) ? val : codeLit(val);
                      const newArgs = {
                        ...args,
                        [parameterName]: newExpr,
                      };
                      if (
                        isPlainObjectPropType(parameterMeta) &&
                        "type" in parameterMeta &&
                        parameterMeta.type === "dataSourceOp"
                      ) {
                        setDataSourceExpr(ensureKnownDataSourceOpExpr(newExpr));
                      }
                      if (val == null) {
                        delete newArgs[parameterName];
                      }
                      actionMeta.resetDependentArgs?.(
                        newArgs,
                        interactionsCtx,
                        parameterName
                      );
                      spawn(
                        sc.change(({ success }) => {
                          interaction.args = Object.entries(newArgs).map(
                            ([name, expr]) =>
                              mkNameArg({
                                name,
                                expr,
                              })
                          );
                          ensureGenericFuncTypes(
                            eventHandlerExpr,
                            eventHandlerKey
                          );
                          return success();
                        })
                      );
                    }}
                    propType={parameterMeta}
                    layout="vertical"
                    disableLinkToProp={true}
                    viewCtx={vc}
                    tpl={tpl}
                    env={actionEnv}
                    componentPropValues={args}
                    ccContextData={interactionsCtx}
                  />
                  {highlightOnMount?.argName === parameterName && (
                    <HighlightBlinker doScroll />
                  )}
                </div>
              );
            }
          )}
      </PlasmicActionBuilder>
      {highlightOnMount && !highlightOnMount.argName && (
        <HighlightBlinker doScroll />
      )}
    </div>
  );
}

const ActionBuilder = observer(React.forwardRef(ActionBuilder_));
export default ActionBuilder;

function getActionMeta(sc: StudioCtx, actionName: string) {
  if (actionName in ACTIONS_META) {
    return ACTIONS_META[actionName] as ActionType<any>;
  } else if (actionName.includes(".")) {
    const [contextName, action] = actionName.split(".");
    const contextMeta =
      sc.getRegisteredContextsMap().get(contextName) ??
      sc.getHostLessContextsMap().get(contextName);
    if (
      !contextMeta ||
      !("globalActions" in contextMeta.meta) ||
      !contextMeta.meta.globalActions
    ) {
      return undefined;
    }
    const globalAction = contextMeta.meta.globalActions[action];
    return globalAction
      ? generateActionMetaForGlobalAction(globalAction)
      : undefined;
  }
  return undefined;
}

function makeDefaultArgs(
  viewCtx: ViewCtx,
  component: Component,
  actionMeta: ActionType<any>
) {
  if (actionMeta.getDefaultArgs) {
    return actionMeta.getDefaultArgs(component);
  } else {
    const args: Record<string, Expr> = {};
    for (const [name, propType] of Object.entries(actionMeta.parameters)) {
      if (
        isPlainObjectPropType(propType) &&
        "defaultValue" in propType &&
        propType["defaultValue"]
      ) {
        assert(
          propType.type !== "slot",
          `Don't support slot content for actions`
        );
        args[name] = codeLit(propType.defaultValue);
      }
    }
    return args;
  }
}
