// This is a skeleton starter React component generated by Plasmic.
// Feel free to edit as you see fit.
import { AppCtx } from "@/wab/client/app-ctx";
import HostUrlInput from "@/wab/client/components/HostUrlInput";
import { Modal } from "@/wab/client/components/widgets/Modal";
import { spawn, swallow } from "@/wab/shared/common";
import React from "react";

interface HostConfigProps {
  appCtx: AppCtx;
  onCancel: () => void;
  onUpdate: () => void;
}

export function AddTrustedHostModal({
  appCtx,
  onCancel,
  onUpdate,
}: HostConfigProps) {
  const [draft, setDraft] = React.useState("");
  const [protocol, setProtocol] = React.useState("https://");
  const draftUrl = protocol + (draft || "").trim();

  const invalidInput =
    !swallow(() => draft && new URL(draftUrl)) || draftUrl?.includes(" ");

  return (
    <Modal
      title={"Add trusted host"}
      visible
      onCancel={() => onCancel()}
      footer={null}
    >
      Plasmic projects can be hosted by your own app! <br />
      It allows you to bring your own components to Plasmic. Learn more about
      Plasmic app-hosting and how to setup your app{" "}
      <a href="https://www.plasmic.app/learn/app-hosting/" target="_blank">
        here
      </a>
      .
      <HostUrlInput
        className="mv-xlg"
        hostProtocolSelect={{
          onChange: (val) => {
            if (val) {
              setProtocol(val);
            }
          },
        }}
        urlInput={{
          props: {
            value: draft || "",
            onChange: (e) => setDraft(e.currentTarget.value ?? ""),
          },
        }}
        confirmButton={{
          props: {
            onClick: () =>
              !invalidInput &&
              spawn(appCtx.api.addTrustedHost(draftUrl).then(() => onUpdate())),
            disabled: invalidInput,
          },
        }}
        clearButton={{
          render: () => null,
        }}
        showPlasmicHostValidations={true}
        urlValidationStatus={
          !draft ? undefined : invalidInput ? "invalid" : "valid"
        }
        urlPathStatus={
          !draft
            ? undefined
            : draftUrl.endsWith("/plasmic-host")
            ? "standard"
            : "nonStandard"
        }
      />
      Remember to only add domains you trust.
    </Modal>
  );
}
