// This is a skeleton starter React component generated by Plasmic.
// Feel free to edit as you see fit.
import Button from "@/wab/client/components/widgets/Button";
import FilterButton from "@/wab/client/components/widgets/FilterButton";
import Searchbox from "@/wab/client/components/widgets/Searchbox";
import { TextboxRef } from "@/wab/client/components/widgets/Textbox";
import {
  DefaultLeftSearchPanelProps,
  PlasmicLeftSearchPanel,
} from "@/wab/client/plasmic/plasmic_kit/PlasmicLeftSearchPanel";
import React from "react";

interface LeftSearchPanelProps extends DefaultLeftSearchPanelProps {
  searchboxProps?: React.ComponentProps<typeof Searchbox>;
  wrapperProps?: React.ComponentProps<"div">;
  filterProps?: React.ComponentProps<typeof FilterButton>;
  expandProps?: React.ComponentProps<typeof Button>;
  collapseProps?: React.ComponentProps<typeof Button>;
}

const LeftSearchPanel = React.forwardRef(function LeftSearchPanel(
  props: LeftSearchPanelProps,
  outerRef: React.Ref<TextboxRef>
) {
  const {
    wrapperProps,
    filterProps,
    searchboxProps,
    expandProps,
    collapseProps,
    ...rest
  } = props;
  return (
    <PlasmicLeftSearchPanel
      searchPanel={{ ...wrapperProps }}
      searchbox={{
        ...searchboxProps,
        ref: outerRef,
      }}
      filterButton={{ ...filterProps }}
      expandButton={{ ...expandProps }}
      collapseButton={{ ...collapseProps }}
      {...rest}
    />
  );
});

export default LeftSearchPanel as React.FunctionComponent<LeftSearchPanelProps>;
