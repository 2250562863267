// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";

import { MenuBuilder } from "@/wab/client/components/menu-builder";
import ActionBuilder from "@/wab/client/components/sidebar-tabs/StateManagement/ActionBuilder";
import { SimpleReorderableList } from "@/wab/client/components/widgets/SimpleReorderableList";
import {
  DefaultHandlerSectionProps,
  PlasmicHandlerSection,
} from "@/wab/client/plasmic/plasmic_kit_state_management/PlasmicHandlerSection";
import { mkDefaultInteraction } from "@/wab/client/state-management/interactions-meta";
import { StudioCtx } from "@/wab/client/studio-ctx/StudioCtx";
import { ViewCtx } from "@/wab/client/studio-ctx/view-ctx";
import { moveIndex, remove, spawn } from "@/wab/shared/common";
import {
  Component,
  EventHandler,
  Expr,
  GenericEventHandler,
  Interaction,
  isKnownGenericEventHandler,
  TplComponent,
  TplTag,
} from "@/wab/shared/model/classes";
import {
  ancestors,
  cloneType,
  EventHandlerKeyType,
  isEventHandlerKeyForAttr,
  isEventHandlerKeyForFuncType,
  isEventHandlerKeyForParam,
  isTplComponent,
  KeyedEventHandler,
} from "@/wab/shared/core/tpls";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { Alert, Menu } from "antd";
import { observer } from "mobx-react";
import { HighlightInteractionRequest } from "@/wab/shared/code-components/code-components";

export interface HandlerSectionProps extends DefaultHandlerSectionProps {
  sc: StudioCtx;
  vc: ViewCtx;
  component: Component;
  tpl: TplComponent | TplTag;
  keyedEventHandler: KeyedEventHandler;
  highlightOnMount?: HighlightInteractionRequest;
  onChange: (expr: Expr) => void;
}

function HandlerSection_(
  props: HandlerSectionProps,
  ref: HTMLElementRefOf<"div">
) {
  const {
    sc,
    vc,
    component,
    tpl,
    keyedEventHandler,
    highlightOnMount,
    onChange,
    ...rest
  } = props;

  const { handler: maybeEventHandler } = keyedEventHandler;
  const [expandedInteraction, setExpandedInteraction] = React.useState<
    Interaction | undefined
  >(
    maybeEventHandler?.interactions.length === 1
      ? maybeEventHandler.interactions[0]
      : highlightOnMount?.interaction ?? undefined
  );

  const onAddNewInteraction = async () => {
    const eventHandler = maybeEventHandler
      ? maybeEventHandler
      : isEventHandlerKeyForFuncType(keyedEventHandler.key)
      ? new GenericEventHandler({
          interactions: [],
          handlerType: cloneType(keyedEventHandler.key.funcType),
        })
      : new EventHandler({ interactions: [] });
    const interaction = mkDefaultInteraction(eventHandler, component);
    spawn(
      sc.change(({ success }) => {
        ensureGenericFuncTypes(eventHandler, keyedEventHandler.key);
        eventHandler.interactions.push(interaction);
        onChange(eventHandler);
        return success();
      })
    );
    setExpandedInteraction(interaction);
  };

  const mkMenu = (interaction: Interaction) => {
    const builder = new MenuBuilder();
    builder.genSection(undefined, (push) => {
      push(
        <Menu.Item
          key="remove-interaction"
          onClick={() =>
            spawn(
              sc.change(({ success }) => {
                if (maybeEventHandler) {
                  remove(maybeEventHandler.interactions, interaction);
                }
                return success();
              })
            )
          }
        >
          Remove
        </Menu.Item>
      );
    });
    return builder.build({
      menuName: "interaction-menu",
    });
  };

  return (
    <PlasmicHandlerSection
      root={{ ref }}
      {...rest}
      addBtn={{
        "data-test-id": "add-new-action",
        onClick: onAddNewInteraction,
      }}
    >
      {isEventHandlerKeyForAttr(keyedEventHandler.key) &&
        keyedEventHandler.key.attr === "onLoad" && (
          <Alert
            type="warning"
            style={{ marginBottom: 16 }}
            message={
              <>
                <p>
                  The "On Load" interaction only has effect for images and other
                  specific "loadable" elements.
                </p>
                <p>
                  If you want to run an interaction when the page is loaded,
                  insert a Side Effect instead.
                </p>
              </>
            }
          ></Alert>
        )}
      {isEventHandlerKeyForParam(keyedEventHandler.key) &&
        keyedEventHandler.key.param.variable.name === "onClick" &&
        isTplComponent(tpl) &&
        tpl.component.name.toLowerCase().includes("button") &&
        ancestors(tpl).some(
          (anc) =>
            isTplComponent(anc) && anc.component.name === "plasmic-antd5-form"
        ) && (
          <Alert
            type="warning"
            style={{ marginBottom: 16 }}
            message={
              <>
                <p>Don't set an "on click" on form submission buttons!</p>
                <p>
                  Select the form component instead, and set the "on submit"
                  interaction there.
                </p>
              </>
            }
          ></Alert>
        )}
      <SimpleReorderableList
        onReordered={(fromIndex, toIndex) =>
          maybeEventHandler &&
          spawn(
            sc.change(({ success }) => {
              if (maybeEventHandler) {
                ensureGenericFuncTypes(
                  maybeEventHandler,
                  keyedEventHandler.key
                );
                moveIndex(maybeEventHandler.interactions, fromIndex, toIndex);
              }
              return success();
            })
          )
        }
        style={{ width: "100%", height: "100%" }}
        customDragHandle
      >
        {maybeEventHandler?.interactions.map((interaction) => (
          <ActionBuilder
            key={interaction.uid}
            sc={sc}
            vc={vc}
            component={component}
            isCollapsed={expandedInteraction !== interaction}
            onCollapseClick={() =>
              setExpandedInteraction((expanded) =>
                expanded === interaction ? undefined : interaction
              )
            }
            eventHandlerExpr={maybeEventHandler}
            tpl={tpl}
            menu={mkMenu(interaction)}
            interaction={interaction}
            eventHandlerKey={keyedEventHandler.key}
            highlightOnMount={
              highlightOnMount?.interaction === interaction
                ? highlightOnMount
                : undefined
            }
          />
        ))}
      </SimpleReorderableList>
    </PlasmicHandlerSection>
  );
}

const HandlerSection = observer(React.forwardRef(HandlerSection_));
export default HandlerSection;

/**
 * Normally, an EventHandler is an arg to an on* param on some component,
 * and the arguments passed to the event handler are defined by the Param.type
 * (which is a FunctionType). However, if we have an EventHandler expr
 * that is buried in some JSON prop (for example, an array prop where each
 * item has a function field), then we do not have a corresponding Param to
 * use for looking up the EventHandler types; that's because an array propType
 * is just an "any" wab type, and we don't store any schema information about the
 * nestable propType into Param.type. So, instead, we store it here, in
 * GenericEventHandler.handlerType. This handlerType will be the type that was read
 * off of the propType and "snapshotted" into this expr, so that we can
 * know what the args are called when we generate the code.
 *
 * Note that if the propType changes the arguments passed to this event handler,
 * then this snapshot will be "wrong", but it's "okay" because the actual actions
 * are probably also wrong (now referencing the wrong event handler arguments). So
 * the user will need to fix up the actions, and we just sync up the types here when
 * this GenericEventHandlerExpr is being updated in HandlerSection and ActionBuilder.
 * (This doesn't work if the only change to the propType is switching the ordering of
 * the arguments, as that doesn't break the actions...)
 *
 * Ideally we'd have better typing available from Param.type for the whole JSON prop,
 * instead of saving snapshots here, but 😔
 */
export const ensureGenericFuncTypes = (
  expr: EventHandler,
  eventHandlerKey: EventHandlerKeyType
) => {
  if (
    isKnownGenericEventHandler(expr) &&
    isEventHandlerKeyForFuncType(eventHandlerKey)
  ) {
    expr.handlerType = cloneType(eventHandlerKey.funcType);
  }
};
