import { analytics, initBrowserAnalytics } from "@/wab/client/analytics";
import { initAmplitudeBrowser } from "@/wab/client/analytics/amplitude-browser";
import { initPosthogBrowser } from "@/wab/client/analytics/posthog-browser";
import { methodForwarder } from "@/wab/commons/methodForwarder";
import { ConsoleLogAnalytics } from "@/wab/shared/analytics/ConsoleLogAnalytics";

declare const COMMITHASH: string;

export function initAnalytics(production: boolean) {
  const amplitudeAnalytics = initAmplitudeBrowser();
  const posthogAnalytics = initPosthogBrowser({});
  initBrowserAnalytics(
    production
      ? methodForwarder(amplitudeAnalytics, posthogAnalytics)
      : methodForwarder(
          new ConsoleLogAnalytics(),
          amplitudeAnalytics,
          posthogAnalytics
        )
  );
  analytics().appendBaseEventProperties({
    production,
    commitHash: COMMITHASH,
  });

  return { amplitudeAnalytics, posthogAnalytics };
}
