// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import { MenuType, useContextMenu } from "@/wab/client/components/ContextMenu";
import {
  DefaultRowItemProps,
  PlasmicRowItem,
} from "@/wab/client/plasmic/plasmic_kit_new_design_system_former_style_controls/PlasmicRowItem";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import * as React from "react";

export interface RowItemProps extends DefaultRowItemProps {
  style?: React.CSSProperties;
  menu?: MenuType;
  onClick?: () => void;
  showActionsOnHover?: boolean;
}

function RowItem_(props: RowItemProps, ref: HTMLElementRefOf<"div">) {
  const { style, menu, menuSize, onClick, showActionsOnHover, ...rest } = props;
  const [hover, setHover] = React.useState(false);
  const contextMenuProps = useContextMenu({ menu });

  return (
    <PlasmicRowItem
      {...rest}
      root={{
        ref,
        style,
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
        onClick,
        ...contextMenuProps,
      }}
      showActions={props.showActions || (showActionsOnHover && hover)}
      menuButton={{ props: { ...contextMenuProps, size: menuSize } }}
    />
  );
}

const RowItem = React.forwardRef(RowItem_);
export default RowItem;
