// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import {
  DefaultStyleToggleButtonProps,
  PlasmicStyleToggleButton,
} from "@/wab/client/plasmic/plasmic_kit_style_controls/PlasmicStyleToggleButton";
import { capitalizeFirst } from "@/wab/shared/strs";
import Tooltip, { TooltipPlacement } from "antd/lib/tooltip";
import * as React from "react";

interface StyleToggleButtonProps extends DefaultStyleToggleButtonProps {
  tooltip?: React.ReactNode;
  tooltipPlacement?: TooltipPlacement;
  value: string;
  onClick?: (e: React.MouseEvent) => void;
  "data-plasmic-prop"?: string;
}

function StyleToggleButton(props: StyleToggleButtonProps) {
  const {
    tooltip,
    tooltipPlacement,
    value,
    isDisabled,
    onClick,
    "data-plasmic-prop": dataPlasmicProp,
    ...rest
  } = props;
  return (
    <PlasmicStyleToggleButton
      {...rest}
      onClick={(e) => {
        if (isDisabled) {
          // We handle isDisabled explicitly this way, instead of setting disabled
          // attr, because otherwise we cannot capture the mouse events necessary
          // to show the disabledTooltip in StyleToggleButtonGroup
          e.stopPropagation();
          e.preventDefault();
        } else {
          onClick?.(e);
        }
      }}
      isDisabled={isDisabled}
      root={{
        // @ts-expect-error
        props: {
          "aria-disabled": isDisabled,
          "data-plasmic-prop": dataPlasmicProp,
        },
        // @ts-expect-error
        wrap: (x) =>
          !isDisabled ? (
            <Tooltip
              title={tooltip ?? capitalizeFirst(value)}
              placement={tooltipPlacement}
            >
              {x}
            </Tooltip>
          ) : (
            x
          ),
      }}
    />
  );
}

export default StyleToggleButton;
